import ReaderResultListView from 'views/newsroom/readerResultListView';
import ReaderResultApiCollection from 'collections/newsroom/readerResultApiCollection';
import TimelineCollectionListController from 'controllers/timelineCollectionListController';
import when from 'when';
import vent from 'libraries/vent';
import DragAndDropManager from 'models/dragAndDropManager';
import _ from 'underscore';
import appState from 'models/appStateInstance';
import Notificator from 'libraries/notificator'
import I18n from 'i18n';

class ReaderResultListController extends TimelineCollectionListController {
    initialize(options) {
        this.archiveDateId = options.readerArchiveDateId || options.dateModel.get('id');
        TimelineCollectionListController.prototype.initialize.apply(this, [options]);
        this.listenTo(vent, 'agentResult:loaded', this._handleAgentResultLoaded);
        this.listenTo(vent, 'reInitializeDragAndDrop', this._reRenderAfterDragnDrop);
    }

    reInitializeDragAndDrop(archiveDateId) {
        if (this.archiveDateId === archiveDateId) {
            this.view._initializeDragnDrop();
            this.view.enableSortable();
        }
    }

    _initializeApiCollection(options) {
        this.readerResults = new ReaderResultApiCollection([], {
            readerConfigurationId: options.readerConfigurationId,
            readerCategoryId: options.readerCategoryId,
            readerArchiveDateId: this.archiveDateId,
            limit: 200
        });
    }

    _initializeListView() {
        this.view = new ReaderResultListView({
            collection: this.readerResults,
            archiveDateId: this.archiveDateId,
            listControllerId: this.listControllerId
        });
    }

    _handleAgentResultLoaded(loadedAgentResult) {
        this.readerResults.each((result) => {
            const agentResult = result.get('agent_result');
            if (agentResult.id === loadedAgentResult.id) {
                agentResult.set(loadedAgentResult.attributes);
            }
        });
    }

    addResultsToCollection(readerResults, newIndex) {
        var readerResultBeforeId,
            readerResultAfterId,
            readerResultAfter,
            readerResultBefore,
            afterIndex,
            beforeIndex;

        afterIndex = newIndex-1;
        beforeIndex = newIndex;

        readerResultAfter = this.view.collection.models[afterIndex];
        readerResultBefore = this.view.collection.models[beforeIndex];

        if (readerResultAfter) {
            readerResultAfterId = readerResultAfter.get('id');
        }
        if (readerResultBefore) {
            readerResultBeforeId = readerResultBefore.get('id');
        }

        this.view.collection.add(readerResults);

        let manager  = new DragAndDropManager(this.view.collection,
                                              readerResults,
                                              readerResultAfterId,
                                              readerResultBeforeId,
                                              this.archiveDateId);
        manager.dragAndDrop();

        this._updateApiPositions(
            readerResults,
            readerResultBeforeId,
            readerResultAfterId,
            this.archiveDateId
        );

        this._reRenderAfterDragnDrop();
    }

    removeResultsFromCollection(readerResults) {
        var self = this;
        _.each(readerResults, function (m) { self.view.collection.remove(m); });
        this._reRenderAfterDragnDrop();
    }

    getArrayPositions(results) {
        return _.map(results, (result) => {
            return this.readerResults.models.indexOf(result);
        });
    }

    moveResultsInCollection(readerResults, attrs) {
        let newIndex = attrs.newIndex;

        let up = newIndex < _.max(this.getArrayPositions(readerResults));
        var readerResultBeforeId,
            readerResultAfterId,
            readerResultAfter,
            readerResultBefore;
        var afterIndex, beforeIndex;
        if (up) {
            afterIndex = newIndex-1;
            beforeIndex = newIndex;
        } else {
            afterIndex = newIndex;
            beforeIndex = newIndex+1;
        }
        readerResultAfter = this.readerResults.models[afterIndex];
        readerResultBefore = this.readerResults.models[beforeIndex];

        if (readerResultAfter) {
            readerResultAfterId = readerResultAfter.get('id');
        }
        if (readerResultBefore) {
            readerResultBeforeId = readerResultBefore.get('id');
        }

        let manager  = new DragAndDropManager(this.view.collection,
                                              readerResults,
                                              readerResultAfterId,
                                              readerResultBeforeId,
                                              this.archiveDateId);
        manager.dragAndDrop();

        this._updateApiPositions(
            readerResults,
            readerResultBeforeId,
            readerResultAfterId,
            this.archiveDateId
        );
        this._reRenderAfterDragnDrop();
    }

    _reRenderAfterDragnDrop() {
        this._startChangingContent(this.view.el);
        this.view.collection.sort();
        this.view.unregisterEventHandler();
        this.view.render();
        this._stopChangingContent();
        this.reInitializeDragAndDrop(this.archiveDateId);
    }

    _updateApiPositions(readerResults,
                        readerResultBeforeId,
                        readerResultAfterId,
                        archiveDateId) {
        appState.requestStart();
        let ids = readerResults.map(function(rr) {
            return rr.get('id');
        });

        let data = {
            ids: ids,
            before_reader_result_id: readerResultBeforeId,
            after_reader_result_id: readerResultAfterId,
            archive_date_id: +archiveDateId
        };

        readerResults[0].batchUpdate({
            data: data,
            processData: true,
            success: () => {
                appState.requestCompleted();
            },
            error: () => {
                const message =
                    I18n.t('webapp.reader_admin.update_result_positions_error');
                Notificator.showNotification(message);
                appState.requestCompleted();
            }
        });
    }

    _initializeListItemViews() {
        this.readerResultListItemViews = {};
    }

    shownItems() {
        return this.readerResults.slice(0, this.readerResults.length);
    }

    loadListCollection(options) {
        let collection = options.collection;

        let handleResponse = () => {
          this._startChangingContent(this.view);
          this._appendItemsFinished();
          this._initialResultsShown();
          this._stopChangingContent(this.view);
        }

        if (collection) {
            this._startChangingContent(this.view);
            this.readerResults.add(collection);
            this.readerResults.moreObjectsAvailable = false;
            this._appendItemsFinished();
            when().then(this._initialResultsShown);
            this._stopChangingContent(this.view);
        } else {
            this.readerResults.fetch({
                success: handleResponse,
                error: handleResponse
            });
        }
    }

    handleNoItemsToDisplay() {
        if (this.readerResults.isEmpty()) {
            this.view.showNoResultsMessage();
        }
    }

    collectionItemViews() {
        return this.readerResultListItemViews;
    }

    appendCollection() {
        return this.readerResults.appendResults();
    }

    collectionSize() {
        return this.readerResults.size();
    }

    allItemsLoaded() {
        return this.readerResults.allItemsLoaded();
    }

    _registerItemView(itemView) {
        var model = itemView.model;
        if (this.readerResultListItemViews[model.id] === itemView) { return; }
        this.readerResultListItemViews[model.id] = itemView;
        this.listenTo(itemView, 'item:before:close', () => {
            this._startChangingContent(itemView);
        });
        this.listenTo(itemView, 'item:closed', () => {
            this._stopChangingContent();
        });
    }
}

export default ReaderResultListController;
