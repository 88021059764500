import Marionette from 'backbone.marionette';
import readerResultListTemplate from 'text-loader!templates/newsroom/readerResultList.html';
import ReaderResultListItemView from 'views/newsroom/readerResultListItemView';
import _ from 'underscore';
import $ from 'jquery';
import Sortable from 'sortablejs';
import vent from 'libraries/vent';
import session from 'models/sessionInstance';

class ReaderResultListView extends Marionette.CompositeView {
    initialize() {
        _.bindAll(this, 'enableSortable', 'disableSortable');
        this.archiveDateId = this.options.archiveDateId;
        this.listControllerId = this.options.listControllerId;
        this._showPreloader = true;
        this._showNoResultMessage = false;
        this.listenTo(
            vent,
            'enableSortable',
            this.enableSortable
        );
        this.listenTo(
            vent,
            'disableSortable',
            this.disableSortable
        );
    }

    onRender() {
        this.updateElementsVisibility();
        this.$el.find(this.ui.results).on('mousedown', this,
            this.checkStateMismatch);
    }

    checkStateMismatch(event) {
        let view = event.data;
        let readerResultsInView = view.ui.results
            .find('[data-reader-result-id]').length;
        let collectionLength = view.collection.length;
        if (readerResultsInView !== collectionLength) {
            view.unregisterEventHandler();
            view.sortable.destroy();
            view.render();
            view._initializeDragnDrop();
        }
    }

    onClose() {
        this.unregisterEventHandler();
    }
    
    unregisterEventHandler() {
        this.$el.find(this.ui.results).off('mousedown',
            this.checkStateMismatch);

    }

    serializeData() {
        return {
            archiveDateId: this.archiveDateId,
            listControllerId: this.listControllerId
        };
    }

    appendHtml(collectionView, itemView, index) {
        var container = collectionView.ui.results;
        var followingElement = container.children()[index];
        if (followingElement) {
            collectionView.$(followingElement).before(itemView.el);
        } else {
            container.append(itemView.el);
        }
    }

    updateElementsVisibility() {
        if (!_.isString(this.ui.preloader)) {
            if (this._showPreloader) {
                this.ui.preloader.show();
            } else {
                this.ui.preloader.hide();
            }
        }
        if (!_.isString(this.ui.noResultsMessage)) {
            if (this._showNoResultMessage) {
                this.ui.noResultsMessage.show();
            } else {
                this.ui.noResultsMessage.hide();
            }
        }
    }

    showNoResultsMessage() {
        this._showPreloader = false;
        this._showNoResultMessage = true;
        this.updateElementsVisibility();
    }

    allItemsLoaded() {
        this.updateElementsVisibility();
    }

    _initializeDragnDrop() {
        let _this = this;
        let selector = 'reader-result-list-' + this.archiveDateId;
        let element = document.getElementById(selector);

        // The reader category was already changed during the load. No need
        // initializing sortable now.
        if (!element) { return; }

        this.sortable = Sortable.create(element, {
            scroll: true,
            forceAutoscrollFallback: true,
            forceFallback: true,
            fallbackTolerance: 4,
            scrollSensitivity: 50,
            scrollSpeed: 15,
            bubbleScroll: true,
            group: "ReaderResults",
            /** Fixes chrome bug with :hover style that is left hanging if
             *  element is dragged so that its left edge is over the category
             *  in left navigation menu */
            chosenClass: 'is-dragged',
            ghostClass: 'is-ghost-dragged',
            onStart: (event) => {
                $('#sidebar-content .nav-tree').css('pointer-events', 'none');
                // var el = $($(event.from).children()[event.oldIndex]);
                vent.trigger('dragStart', this.collection.at(event.oldIndex));
                // if (el.find('.clips-list__item-drop').length === 0) {
                //     el.prepend('<div class="clips-list__item-drop"></div><div class="clips-list__item-drop"></div>');
                // }

            },
            onEnd: (event) => {
                setTimeout(() => {
                    $('#sidebar-content .nav-tree').css('pointer-events', 'auto');
                }, 100);
                let destinationControllerId = $(event.to).attr('data-list-controller-id');
                let sourceControllerId = $(event.from).attr('data-list-controller-id');
                vent.trigger('dragStop');
                _this.disableSortable();
                let attributes = {
                    readerResultId: event.item.dataset.readerResultId,
                    newIndex: event.newIndex,
                    destinationControllerId: destinationControllerId,
                    sourceControllerId: sourceControllerId,
                };
                vent.trigger('readerResultsMoved', attributes);
                vent.trigger('reInitializeDragAndDrop', this.archiveDateId);
            },
            onRemove: () => {
                if (this.$('li').length === 1) {
                    this.$('[no-results-message]').show();
                }
            },
        });
    }

    // Edge case of the very last result for the ArchiveDate, where index goes
    // over the array length if the result is dragged to be the very last item
    // on the list
    _findNewPosition(index) {
        let result = this.collection.at(index);
        if (result) {
            return result.get('position') + 1;
        } else {
            return 0;
        }
    }

    disableSortable() {
      var self = this;
      setTimeout(function () {
        if (!_.isUndefined(self.sortable)) {
            self.sortable.option('disabled', true);
        }
    }, 1);
    }

    enableSortable() {
      var self = this;
      setTimeout(function () {
        if (!_.isUndefined(self.sortable)) {
            self.sortable.option('disabled', false);
        }
      });
    }

    showPreloader() {
        if (!_.isString(this.ui.preloader)) {
            this._showPreloader = true;
            this.updateElementsVisibility();
        }
    }

    fetchFinished() {
        this._showPreloader = false;
        this.updateElementsVisibility();
        if (session.get('device_type') === 'desktop') {
            this._initializeDragnDrop();
        }
    }
}

ReaderResultListView.prototype.template = readerResultListTemplate;
ReaderResultListView.prototype.itemView = ReaderResultListItemView;
ReaderResultListView.prototype.itemViewContainer = "[data-reader-result-list]";
ReaderResultListView.prototype.ui = {
    results: "[data-reader-result-list]",
    preloader: '[data-preloader]',
    noResultsMessage: '[no-results-message]'
};

export default ReaderResultListView;
